import {API_URL} from "../core/links";
import axios from "axios";
import md5 from "md5";
import Cookies from "js-cookie";
import moment from "moment";
import {SafeForWorkType} from "../pages/visibility/components/SafeForWork";

export const userSession = () => {

  return new Promise<null | {user: object, token: string, profiles: any[]}>(async (resolve, reject) => {

    const token = Cookies.get('token');

    if (token !== undefined) {

      try {

        const apiResponse = await apiUserSession(token);
        resolve({
          token: apiResponse.token,
          user: apiResponse.user,
          profiles: apiResponse.profiles,
        });

      } catch (error) {
        resolve(null);
      }

    }

    resolve(null);

  })

}

export const apiUserSession = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/v2/user.session?hey=1', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserConfirmCreate = (phone: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/hey/user.create_confirm', {
      md5: md5("phonesecretwork" + phone),
      phone
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserLogin = (email: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/v2/user.login', {
      email,
      password,
      hey: 1,
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserRegister = (email: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/v2/user.register', {
      email,
      password,
      hey: 1,
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserConfirmCheck = (id: number, code: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/hey/user.confirm_check', {
      id,
      code,
      fcm: 'webapp'
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetData = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/v2/user.get_data', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetBadges = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/v2/user.get_badges', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.badges);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetShareInfo = (token: string, target_user_id: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/user.get_share_info?target_user_id=${target_user_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.info);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserUpdateLocation = (token: string, lat: string, lng: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.update_location', {
      lat,
      lng,
      timestamp: moment().unix(),
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserUpdatePhotosInSwitcher = (token: string, photos_in_switcher: number): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.change_photos_in_switcher', {
      value: photos_in_switcher
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserUpdateStealthMode = (token: string, stealth_mode: number): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.change_stealth_mode', {
      value: stealth_mode
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserChangeExploreLocation = (token: string, lat: number, lng: number): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.change_explore_location', {
      lat,
      lng
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserChangeExplore = (token: string, explore: number): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.change_explore', {
      value: explore
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserSendSupport = (
  token: string,
  app_crash: number,
  complaint: number,
  restore_the_access: number,
  text: string
  ): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append('app_crash', app_crash.toString());
    data.append('complaint', complaint.toString());
    data.append('', restore_the_access.toString());
    data.append('text', text);

    axios.post(API_URL + '/user.send_support', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserChangeSafeForWork = (
  token: string,
  safe_for_work: SafeForWorkType
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.change_safe_for_work', {
      safe_for_work: +safe_for_work.enabled,
      safe_for_work_lat: safe_for_work.lat,
      safe_for_work_lng: safe_for_work.lng,
      safe_for_work_begin: safe_for_work.begin,
      safe_for_work_end: safe_for_work.end
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserChangeShare = (token: string, target_user_id: string, type: string, value: Number): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/v2/user.change_share', {
      target_user_id,
      type,
      value
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserChangeSex = (
  token: string,
  sex: string
): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/hey/user.change_sex`,
        {
          sex,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        },
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
