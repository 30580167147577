import React from 'react';

type UserPageElementType = {
  element: any,
  values: any
}

function UserPageElement({element, values}: UserPageElementType) {

  return (
    <div className="user-page-block">
      {element.type === 'tips' && (
        <>
          <span className="user-page-block-title">{element.label}</span>
          <div className="user-page-block-tips">
            {values.map((tip: any) => {
              return (
                <span className="user-page-block-tip">{tip.value}</span>
              )
            })}
          </div>
        </>
      )}
      {element.type === 'value' && (
        <span className="user-page-block-title">{element.label}: {values[0].value}</span>
      )}
    </div>
  );
}

export default UserPageElement
