import React, {useState} from 'react';

const infoIcon = require('../../../assets/info.png');

function VisibilityTab({label, content, hasInfo, onOpenInfo}: any) {

  const [open, setOpen] = useState(false)

  return (
    <div className="visibility-tab">
      {hasInfo === true && (
        <img onClick={() => {
          onOpenInfo();
        }} src={infoIcon} alt="" className="visibility-tab-top-info" />
      )}
      <div className={`visibility-tab-top ${open  ? 'active' : ''}`} onClick={() => {
        setOpen(!open)
      }}>
        <span className="visibility-tab-top-label">
          {label}
        </span>
        <span className="visibility-tab-top-icon">{open ? '-' : '+'}</span>
      </div>
      {open && (
        <div className="visibility-tab-content">
          {content}
        </div>
      )}
    </div>
  );
}

export default VisibilityTab;
