import React, {useState} from 'react';
import './Register.css';
import RegisterFields from "./components/RegisterFields";
import Otp, {ConfirmType} from "../../components/Otp";
import Activation from "../../components/Activation";

//const promo = require('../../assets/promo.jpg');

function Register() {

  const [page, setPage] = useState('login');
  const [confirm, setConfirm] = useState<ConfirmType | null>(null)
  const [email, setEmail] = useState<string | null>(null)

  return (
    <div className="login-page">
      <div className="container with-flex-row">
        <div className="login-page-content">
          {page === 'activation' && (
            <Activation
              email={email}
              onBack={() => {
                setEmail(null)
                setPage('login')
              }}
            />
          )}
          {page === 'login' && (
            <RegisterFields
              onSetConfirm={(confirm) => {
                setConfirm(confirm)
                setPage('otp')
              }}
              onSetActivationEmail={(email) => {
                setEmail(email)
                setPage('activation')
              }}
            />
          )}
          {page === 'otp' && (
            <Otp
              confirm={confirm}
              onBack={() => {
                setConfirm(null)
                setPage('login')
              }}
            />
          )}
        </div>
        <div className="login-page-image" style={{}}/>
      </div>
    </div>
  );
}

export default Register;
