import React, {useEffect, useState} from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Login from "./pages/login/Login";
import NoAuth from "./layout/NoAuth";
import Auth from "./layout/Auth";
import Profiles from "./pages/profiles/Profiles";
import {useDispatch, useSelector} from "react-redux";
import {
  apiUserGetBadges,
  apiUserGetData,
  apiUserUpdateLocation,
  userSession
} from "./actions/userActions";
import Users from "./pages/users/Users";
import Loading from "./layout/Loading";
import Settings from "./pages/settings/Settings";
import ProfileEdit from "./pages/edit/ProfileEdit";
import Register from "./pages/register/Register";
import Messages from "./pages/messages/Messages";
import UserPage from "./pages/userpage/UserPage";
import {
  setBadgesAction,
  setLocationAction,
  setTemplatesAction, setUserDataAction,
  userSignInAction
} from "./reducers/common";
import {apiProfileGetData} from "./actions/profileActions";
import {
  clearProfileDataAction,
  setActiveChatAction,
  setChatsAction,
  setPending,
  setProfileDataAction
} from "./reducers/profile";
import {apiApplicationGetData} from "./actions/applicationActions";
import Notifications from "./pages/notification/Notifications";
import PageNotFound from "./pages/pagenotfound/PageNotFound";
import {apiChatsGet} from "./actions/chatActions";
import Stats from "./pages/stats/Stats";
import Support from "./pages/support/Support";
import Visibility from "./pages/visibility/Visibility";
import BlockedUsers from "./pages/users/BlockedUsers";
import Search from "./pages/search/Search";

function App() {

  const dispatch = useDispatch();
  const type = useSelector((state: any) => state.common.type);
  const token = useSelector((state: any) => state.common.token);
  const profiles = useSelector((state: any) => state.common.profiles);
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    checkSession()

  }, [])

  useEffect(() => {
    if (token !== null) {
      initCommonState(token)
    }
  }, [token])

  useEffect(() => {

    if (type !== null) {
      initProfileState()
    }

  }, [type])

  const checkSession = async () => {

    const session = await userSession();
    if (session === null) {
      setLoading(false);
    } else {
      dispatch(userSignInAction({
        user: session.user,
        profiles: session.profiles,
        token: session.token
      }));
      updateLocation(session.token)
      setLoading(false);
    }

  }

  const initCommonState = async (token: string) => {

    loadBadges(token)
    loadUserData(token)
    loadTemplates(token)

  }

  const updateLocation = (token: string) => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        apiUserUpdateLocation(token, position.coords.latitude.toString(), position.coords.longitude.toString())
        dispatch(setLocationAction({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }))
      })
    }

  }

  const loadBadges = async (token: string) => {

    const badges = await apiUserGetBadges(token)
    dispatch(setBadgesAction({
      badges
    }));

  }

  const loadUserData = async (token: string) => {

    const data = await apiUserGetData(token)
    dispatch(setUserDataAction({
      allows: data.allows,
      visibles: data.visibles,
    }));

  }

  const loadTemplates = async (token: string) => {

    const data = await apiApplicationGetData(token)
    dispatch(setTemplatesAction({
      templates: data.templates
    }));

  }

  const initProfileState = async () => {

    dispatch(clearProfileDataAction({}))

    const profile = profiles.find((profile: any) => profile.type === type)
    if (profile !== undefined) {

      loadProfileData(profile.id)
      loadChats(profile.id)

    }

  }

  const loadProfileData = async (profile_id: string) => {

    const data = await apiProfileGetData(token, profile_id)
    dispatch(setProfileDataAction({
      flexes: data.flexes,
      favourites: data.favourites,
      blocks: data.blocks
    }))

  }

  const loadChats = async (profile_id: string) => {

    const chats = await apiChatsGet(token, profile_id)
    dispatch(setChatsAction({
      chats
    }))
    dispatch(setPending({
      type: 'chats',
      value: false
    }))
    dispatch(setActiveChatAction({
      active_chat: chats.length > 0 ? chats[0] : null
    }))

  }


  if (loading) {
    return (
      <Loading/>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Auth/>}>
          <Route path="/" element={<Profiles/>} />

          <Route path="/profiles" element={<Profiles/>} />

          <Route path="/profile/friend" element={<ProfileEdit type={'friend'}/>} />
          <Route path="/profile/flirt" element={<ProfileEdit type={'flirt'}/>} />
          <Route path="/profile/fun" element={<ProfileEdit type={'fun'}/>} />

          <Route path="/messages" element={<Messages/>} />

          <Route path="/notifications" element={<Notifications/>} />

          <Route path="/stats" element={<Stats/>} />

          <Route path="/support" element={<Support/>} />

          <Route path="/visibility" element={<Visibility/>} />

          <Route path="/settings" element={<Settings/>} />

          <Route path="/search" element={<Search/>} />

          <Route path="/users" element={<Users/>} />
          <Route path="/users/blocked" element={<BlockedUsers/>} />
          <Route path="/user/:id" element={<UserPage/>} />

        </Route>
        <Route element={<NoAuth/>}>
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
        </Route>
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
