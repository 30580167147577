import React, {useEffect, useState} from 'react';
import './Visibility.css';
import {useDispatch, useSelector} from "react-redux";
import Switch from "react-switch";
import {setStealthModeAction, userSignOutAction} from "../../reducers/common";
import VisibilityTab from "./components/VisibilityTab";
import Explore from "./components/Explore";
import {apiUserUpdateStealthMode} from "../../actions/userActions";
import SafeForWork from "./components/SafeForWork";
import Modal from "../../components/ui/modal/Modal";

const visibilityIcon = require('../../assets/visibility.png');
const infoIcon = require('../../assets/info.png');

function Visibility() {

  const dispatch = useDispatch()

  const token = useSelector((state: any) => state.common.token);
  const type = useSelector((state: any) => state.common.type);
  const stealth_mode = useSelector((state: any) => state.common.user.stealth_mode)
  const [infoModalObj, setInfoModalObj] = useState<any>(null)

  const [loading, setLoading] = useState(false)

  const logout = () => {
    dispatch(userSignOutAction());
  }

  const setStealthMode = (checked: boolean) => {
    apiUserUpdateStealthMode(token, +checked)
    dispatch(setStealthModeAction({
      stealth_mode: +checked
    }));
  }

  const stealthInfo = (
    <div className="modal-info">
      <span className="modal-info-title">Stealth Mode</span>
      <span className="modal-info-subtitle">
       In stealth mode, you will appear to be “offline but may see nearby users yourself.
      </span>
      <button className="modal-info-button" onClick={() => {
        setInfoModalObj(null)
      }}>Got it</button>
    </div>
  )

  const safeForWorkInfo = (
    <div className="modal-info">
      <span className="modal-info-title">Safe For Work</span>
      <span className="modal-info-subtitle">As with all searches, the more detail you add, the more narrow your results will be.</span>
      <span className="modal-info-subtitle">So keep that in mind as you craft your searches and don’t be too picky.</span>
      <button className="modal-info-button" onClick={() => {
        setInfoModalObj(null)
      }}>Got it</button>
    </div>
  )

  return (
    <>
      <div className="content-area-top">
        <h1 className="content-area-title">Visibility</h1>
      </div>
      <div className="visibility">
        {loading ? (
          <div className="spinner"/>
        ) : (
          <div className="visibility-row">
            <Modal
              open={infoModalObj !== null}
              onClose={() => {
                setInfoModalObj(null)
              }}
              title={null}
              content={(
                infoModalObj
              )}
              hasBottom={false}
            />
            <div className="visibility-left">
              <div className="visibility-element">
                <span className="visibility-element-label">Stealth Mode</span>
                <img onClick={() => {
                  setInfoModalObj(stealthInfo)
                }} src={infoIcon} alt="" className="visibility-element-icon" />
                <div className="visibility-element-right">
                  <Switch
                    onChange={(checked) => {
                      setStealthMode(checked)
                    }}
                    checked={stealth_mode}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
              <VisibilityTab
                label={'Safe For Work'}
                hasInfo={true}
                onOpenInfo={() => {
                  setInfoModalObj(safeForWorkInfo);
                }}
                content={(
                  <SafeForWork/>
                )}
              />
              <VisibilityTab
                label={'Explore'}
                content={(
                  <Explore/>
                )}
              />
            </div>
            <div className="visibility-right">
              <span className="visibility-right-text">Explore a new area</span>
              <span className="visibility-right-text">Update your location during work hours</span>
              <span className="visibility-right-text">Set timers to let you know how long you have been on MUSL</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Visibility;
