import React from 'react';
import './Header.css';
import {APPLE_STORE_LINK, GOOGLE_PLAY_LINK, SITE_URL} from "../../../core/links";

const appleIcon = require('../../../assets/apple.png');
const googleIcon = require('../../../assets/google.png');
const barIcon = require('../../../assets/bar.png');
const appleIconMobile = require('../../../assets/apps/app-store.jpg');
const googleIconMobile = require('../../../assets/apps/play-store.jpg');

function Header({onMobileMenuOpen} : any) {
  return (
    <header className="header">
      <div className="container with-flex-row">
        <a href={'/'} className="header-logo">
          <img src='/images/logo.svg' alt=""/>
        </a>
        <div className="header-right">
          <ul className="header-menu">
            <li><a href={SITE_URL}>About us</a></li>
            <li><a href={SITE_URL}>Blog</a></li>
            <li><a href={SITE_URL}>Contact Us</a></li>
          </ul>
          <div className="header-apps">
            <a target={"_blank"} href={APPLE_STORE_LINK} className="header-apps-link apple"><img src={appleIcon} alt=""/></a>
            <a target={"_blank"}  href={GOOGLE_PLAY_LINK} className="header-apps-link google"><img src={googleIcon} alt=""/></a>
          </div>
        </div>
        <div className="header-mobile-right">
          <div className="header-mobile-apps">
            <a target={"_blank"} href={APPLE_STORE_LINK} className="header-mobile-apps-link apple"><img src={appleIconMobile} alt=""/></a>
            <a target={"_blank"}  href={GOOGLE_PLAY_LINK} className="header-mobile-apps-link google"><img src={googleIconMobile} alt=""/></a>
          </div>
          <span className="header-bar" onClick={() => {
            onMobileMenuOpen()
          }}>
          <img className="header-bar-icon" src={barIcon} alt=""/>
        </span>
        </div>
      </div>
    </header>
  );
}

export default Header;
