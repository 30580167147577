import React from 'react';
import {Circle, GoogleMap, Marker, withGoogleMap} from "react-google-maps";

export const MapComponent = withGoogleMap((props: any) =>
  // @ts-ignore
  <GoogleMap
    defaultZoom={10}
    defaultCenter={props.coords}
    options={{streetViewControl: false, fullscreenControl: false, controlSize: 30, }}
    onClick={(event) => {
      props.onClick(event)
    }}
    center={props.coords}
  >
    <Marker
      position={props.coords}
    />
  </GoogleMap>
)

export const MapComponentWithCircle = withGoogleMap((props: any) =>
  // @ts-ignore
  <GoogleMap
    defaultZoom={10}
    defaultCenter={props.centerCoords}
    options={{streetViewControl: false, fullscreenControl: false, controlSize: 30}}
    center={props.centerCoords}
  >
    <Circle
      center={props.centerCoords}
      radius={8000}
      options={{
        strokeColor: 'rgba(77,146,223,0.40)',
        fillColor: 'rgba(77,146,223,0.40)',
      }}
      onClick={(event) => {
        props.onClick(event)
      }}
    />
    <Marker
      position={props.coords}
    />
  </GoogleMap>
)

