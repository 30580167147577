import React, {useState} from 'react';

type SearchUsernameProps = {
  username: string,
  onChange: any
}

function SearchUsername({username, onChange}: SearchUsernameProps) {

  const [open, setOpen] = useState(false)

  return (
    <div className="search-tab">
      <div className={`search-tab-top ${open  ? 'active' : ''}`} onClick={() => {
        setOpen(!open)
      }}>
        <span className="search-tab-top-label">Search By Username</span>
        <span className="search-tab-top-icon">{open ? '-' : '+'}</span>
      </div>
      {open && (
        <div className="search-tab-content">
          <input
            placeholder={"Search username"}
            type="text"
            className="search-tab-input"
            value={username}
            onChange={(event) => {
              onChange(event.target.value)
            }}
          />
        </div>
      )}
    </div>
  );
}

export default SearchUsername;
