import React, {useState} from 'react';
import './Search.css';
import {useDispatch, useSelector} from "react-redux";
import SearchTab from "./components/SearchTab";
import {setSearchUsernameAction, setSearchValuesAction} from "../../reducers/search";
import { Link } from 'react-router-dom';
import SearchTags from "./components/SearchTags";
import SearchUsername from "./components/SearchUsername";

function Search() {

  const dispatch = useDispatch();

  const type = useSelector((state: any) => state.common.type);
  const templates = useSelector((state: any) => state.common.templates)
  const values = useSelector((state: any) => state.search.values)
  const username = useSelector((state: any) => state.search.username)


  const changeValue = (id: number, value: string, multiply = false) => {

    let index = -1;
    if (multiply) {
      values.forEach((_value: any, i: number) => {
        if (_value.type_id === id && _value.value === value) {
          index = i;
          return;
        }
      })
    } else {
      index = values.map((_value: any) => _value.type_id).indexOf(id)
    }

    if (index === -1) {
      values.push({
        type_id: id,
        value
      })
    } else {
      if (multiply) {
        values.splice(index, 1)
      } else {
        values[index].value = value
      }
    }

    dispatch(setSearchValuesAction({
      values
    }))

  }

  return (
    <>
      <div className="content-area-top">
        <h1 className="content-area-title">Search</h1>
      </div>
      <div className="search">
        {templates[type].search.length === 0 && (
          <div className="edit-profile-loading"/>
        ) ? (
          <div className="spinner"/>
        ) : (
          <div className="search-row">
            <div className="search-left">
              {templates[type].search.filter((block: any) => ['list', 'tips'].indexOf(block.type) !== -1).map((block: any) => {
                return (
                  <SearchTab
                    searchBlock={block}
                    values={values}
                    onChange={changeValue}
                  />
                )
              })}
              <SearchTags
                tags={[]}
                onChange={() => {}}
              />
              <SearchUsername
                username={username}
                onChange={(value: string) => {
                  dispatch(setSearchUsernameAction({
                    username: value
                  }))
                }}
              />
              <Link to={'/users?mode=search'} className="search-submit">Submit</Link>
            </div>
            <div className="search-right">
              <span className="search-right-text">As with all searches, the more detail you add, the more narrow your results will be.</span>
              <span className="search-right-text">So keep that in mind as you craft your searches and don’t be too picky.</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Search;
