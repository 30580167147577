import React, {useState} from 'react';
import Modal from "../../../components/ui/modal/Modal";
import {MapComponent} from "../../../components/ui/MapComponent";
import {useDispatch, useSelector} from "react-redux";
import {apiUserUpdateLocation} from "../../../actions/userActions";
import {setLocationAction} from "../../../reducers/common";

const infoIcon = require('../../../assets/info.png');

function LocationTab({}: any) {

  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const section = urlParams.get("section");

  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [open, setOpen] = useState(section === 'location')

  const token = useSelector((state: any) => state.common.token);
  const lat = useSelector((state: any) => state.common.user.lat);
  const lng = useSelector((state: any) => state.common.user.lng);

  let mapLat = lat !== null ? parseFloat(lat) : 34.09001
  let mapLng = lat !== null ? parseFloat(lng) : -118.36174

  return (
    <>
      <Modal
        open={infoModalOpen}
        onClose={() => {
          setInfoModalOpen(false)
        }}
        title={null}
        content={(
          <div className="modal-info">
            <span className="modal-info-title">Location</span>
            <span className="modal-info-subtitle">About your location text</span>
            <button className="modal-info-button" onClick={() => {
              setInfoModalOpen(false)
            }}>Got it</button>
          </div>
        )}
        hasBottom={false}
      />
      <div className="settings-tab">
        <img onClick={() => {
          setInfoModalOpen(true)
        }} src={infoIcon} alt="" className="settings-tab-top-info" />
        <div className={`settings-tab-top ${open  ? 'active' : ''}`} onClick={() => {
          setOpen(!open)
        }}>
          <span className="settings-tab-top-label">Your location</span>
          <span className="settings-tab-top-icon">{open ? '-' : '+'}</span>
        </div>
        {open && (
          <div className="settings-tab-content">
            <MapComponent
              containerElement={<div style={{ height: `300px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              // @ts-ignore
              onClick={(event) => {
                const lat = event.latLng?.lat();
                const lng = event.latLng?.lng();

                apiUserUpdateLocation(token, lat.toString(), lng.toString())
                dispatch(setLocationAction({
                  lat: lat.toString(),
                  lng: lng.toString()
                }))

              }}
              coords={{ lat: mapLat, lng: mapLng}}
            />
            <span className="visibility-map-bottom-text">Click the map to change your location</span>
          </div>
        )}
      </div>
    </>
  );
}

export default LocationTab
