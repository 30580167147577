import React, {useEffect, useState} from 'react';
import './Stats.css';
import {useSelector} from "react-redux";
import {apiProfileGetStatistics} from "../../actions/profileActions";

function Stats() {

  const token = useSelector((state: any) => state.common.token);
  const type = useSelector((state: any) => state.common.type);

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({})

  useEffect(() => {
    loadStatistics()
  }, [])

  const loadStatistics = async () => {

    setLoading(true)

    const response = await apiProfileGetStatistics(token, type);
    setData(response)
    setLoading(false)

  }

  return (
   <>
     <div className="content-area-top">
       <h1 className="content-area-title">Stats</h1>
     </div>
    <div className="stats">
      {loading ? (
        <div className="spinner"/>
      ) : (
        <div className="stats-elements">
          <div className="stats-element">
            <span className="stats-element-label">Profile Views</span>
            <div className="stats-element-values">
              <div className="stats-element-value">
                <b>{data.views_7 ? data.views_7 : 0}</b>
                <span>Profile views in the last 7 days</span>
              </div>
              <div className="stats-element-value">
                <b>{data.views ? data.views : 0}</b>
                <span>Total profiles view</span>
              </div>
            </div>
          </div>
          <div className="stats-element">
            <span className="stats-element-label">Waves</span>
            <div className="stats-element-values">
              <div className="stats-element-value">
                <b>{data.flexes_7 ? data.flexes_7 : 0}</b>
                <span>Waves received in the last 7 days</span>
              </div>
              <div className="stats-element-value">
                <b>{data.flexes ? data.flexes : 0}</b>
                <span>Total waves received</span>
              </div>
            </div>
          </div>
          <div className="stats-element">
            <span className="stats-element-label">Photos</span>
            <div className="stats-element-values">
              <div className="stats-element-value">
                <b>{data.photos ? data.photos : 0}</b>
                <span>Total number of times your photos have been seen</span>
              </div>
            </div>
          </div>
          <div className="stats-element">
            <span className="stats-element-label">Chats</span>
            <div className="stats-element-values">
              <div className="stats-element-value">
                <b>{data.chats_src ? data.chats_src : 0}</b>
                <span>Total number of messages sent</span>
              </div>
              <div className="stats-element-value">
                <b>{data.chats_target ? data.chats_target : 0}</b>
                <span>Total number of messages received</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
   </>
  );
}

export default Stats;
