const CLEAR_SEARCH = 'CLEAR_SEARCH';
const SET_SEARCH_VALUES = 'SET_SEARCH_VALUES';
const SET_SEARCH_USERNAME = 'SET_SEARCH_USERNAME';

const defaultState = {
  values: [],
  username: '',
  tags: []
};

type ActionProps = {
  type: string,
  payload: any
}

export const searchReducer = (state = defaultState, action: ActionProps) => {
  switch (action.type) {
    case SET_SEARCH_VALUES:
      return {
        ...defaultState,
        values: [...action.payload.values]
      };
    case SET_SEARCH_USERNAME:
      return {
        ...defaultState,
        username: action.payload.username
      };
    default:
      return state;
  }
};

export const setSearchValuesAction = (payload : object) => ({type: SET_SEARCH_VALUES, payload});
export const setSearchUsernameAction = (payload : object) => ({type: SET_SEARCH_USERNAME, payload});
export const clearSearchAction = (payload : object) => ({type: CLEAR_SEARCH, payload});
