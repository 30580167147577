import React from 'react';

const advertisingImage = require('../../../assets/advertising.jpg')

function Advertising() {

  return (
    <div className="aside-advertising">
      <div className="aside-advertising-inner">
        <img src={advertisingImage} className="aside-advertising-inner-image"/>
      </div>
    </div>
  )

}

export default Advertising;
