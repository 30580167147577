import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {changeTypeAction} from "../../../reducers/common";

const friendText = require('../../../assets/profile/friend-text.png');
const flirtText = require('../../../assets/profile/flirt-text.png');
const funText = require('../../../assets/profile/fun-text.png');

const emptyImage = require('../../../assets/profile/empty.png');

const plusIcon = require('../../../assets/profile/plus.png');

type ProfileEmptyType = {
  type: string
}

function ProfileEmpty({type} : ProfileEmptyType) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const createProfile = () => {
    dispatch(changeTypeAction({
      type
    }))
    navigate(`/profile/${type}`)
  }

  return (
    <div className="profile-empty">
      <div className="profile-empty-block" onClick={createProfile}>
        <div className="profile-empty-circle">
          <img src={emptyImage} alt="" className="profile-empty-circle-image"/>
          <img src={plusIcon} alt="" className="profile-empty-circle-plus"/>
        </div>
        <div className="profile-empty-left"/>
        <div className={`profile-empty-inner ${type}`}>
          <div className="profile-empty-inner-left">
            <span className="profile-empty-name">Create profile</span>
          </div>
          <div className="profile-empty-inner-right">
            {type === 'friend' && <img src={friendText} alt="" className="profile-empty-text-image"/>}
            {type === 'flirt' && <img src={flirtText} alt="" className="profile-empty-text-image"/>}
            {type === 'fun' && <img src={funText} alt="" className="profile-empty-text-image"/>}
          </div>
        </div>
      </div>
      {type === 'friend' && <span className="profile-empty-text"><b>Friend:</b> Find people to hang out with with similar interests</span>}
      {type === 'flirt' && <span className="profile-empty-text"><b>Flirt:</b> Looking for a coffee date or marriage? This is where you go</span>}
      {type === 'fun' && <span className="profile-empty-text"><b>Fun:</b> Here is where you find Hookups and FWBs</span>}
    </div>
  );
}

export default ProfileEmpty;
