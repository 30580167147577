import React, {useState} from 'react';
import Checkbox from "../../../components/ui/Checkbox";
import Radio from "../../../components/ui/Radio";

const infoIcon = require('../../../assets/info.png');

type SearchTabProps = {
  values: any[],
  searchBlock: {
    id: number,
    label: string,
    type: string,
    elements: []
  }
  onChange: any
}

function SearchTab({searchBlock, values, onChange}: SearchTabProps) {

  const [open, setOpen] = useState(false)

  return (
    <div className="search-tab">
      <div className={`search-tab-top ${open  ? 'active' : ''}`} onClick={() => {
        setOpen(!open)
      }}>
        <span className="search-tab-top-label">
          {searchBlock.label}
        </span>
        <span className="search-tab-top-icon">{open ? '-' : '+'}</span>
      </div>
      {open && (
        <div className="search-tab-content">
          {searchBlock.type === 'tips' && <SearchTabCheckboxes searchBlock={searchBlock} values={values} onChange={onChange}/>}
          {searchBlock.type === 'list' && <SearchTabRadios searchBlock={searchBlock} values={values} onChange={onChange}/>}
        </div>
      )}
    </div>
  );
}

function SearchTabCheckboxes({searchBlock, values, onChange} : SearchTabProps) {

  const checkboxValues = values.filter(value => value.type_id === searchBlock.id)

  return (
    <div className="search-tab-checkboxes">
      {searchBlock.elements.map(element => {
        return (
          <Checkbox
            label={element}
            checked={checkboxValues.filter((value: any) => value.value === element).length > 0}
            onChange={() => {
              onChange(searchBlock.id, element, true)
            }}
          />
        )
      })}
    </div>
  )

}

function SearchTabRadios({searchBlock, values, onChange} : SearchTabProps) {

  const radioValues = values.filter(value => value.type_id === searchBlock.id)

  return (
    <div className="search-tab-checkboxes">
      {searchBlock.elements.map(element => {
        return (
          <Radio
            label={element}
            checked={radioValues.filter((value: any) => value.value === element).length > 0}
            onChange={() => {
              onChange(searchBlock.id, element, false)
            }}
          />
        )
      })}
    </div>
  )

}

export default SearchTab;
