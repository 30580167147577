import React, {useEffect, useState} from 'react';
import './ProfileEdit.css';
import {useDispatch, useSelector} from "react-redux";
import Block from "./component/Block";
import ImageUpload from "./component/ImageUpload";
import {Link, useNavigate} from "react-router-dom";
import {apiProfileCreate, apiProfileDelete, apiProfileEdit, apiProfileGet} from "../../actions/profileActions";
import {changeTypeAction, setProfilesAction} from "../../reducers/common";
import Photos, {PhotoType} from "./component/Photos";
import Modal from "../../components/ui/modal/Modal";
import PublicFunPhotos from "./component/PublicFunPhotos";

type ProfileEditType = {
  type: string
}

function ProfileEdit({type}: ProfileEditType) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.common.token)
  const templates = useSelector((state: any) => state.common.templates)
  const profiles = useSelector((state: any) => state.common.profiles);

  const [values, setValues] = useState<any>([])
  const [photos, setPhotos] = useState<PhotoType[]>([])
  const [name, setName] = useState('')
  const [image, setImage] = useState<string | null>(null)
  const [random, setRandom] = useState<0 | 1>(0)
  const [loading, setLoading] = useState(false)
  const [infoModalObj, setInfoModalObj] = useState<any>(null)

  const profile = profiles.find((_profile: any) => _profile.type === type)

  useEffect(() => {

    if (profile !== undefined) {
      loadProfile()
    }

  }, [profile])

  const loadProfile = async () => {

    setLoading(true)

    const response = await apiProfileGet(token, profile.id);

    setName(response.profile.name)
    setImage(response.profile.avatar)
    setRandom(response.profile.random)
    setValues(response.values.map((value: any) => {
      return {
        type_id: value.type_id,
        value: value.value
      }
    }))
    setPhotos(response.photos)
    setLoading(false)

  }

  const submit = async () => {

    if (name.length === 0 || image === null) {
      alert("Error")
      return
    }

    setLoading(true)
    const response = profile === undefined ? await apiProfileCreate(
        token,
        type,
        name,
        image,
        values
      ) : await apiProfileEdit(
      token,
      profile.id,
      name,
      image,
      random,
      values
    )
    dispatch(setProfilesAction({
      profiles: response.profiles
    }))
    navigate('/users')
    setLoading(false)

  }

  const changeValue = (id: number, value: string, multiply = false) => {

    let index = -1;
    if (multiply) {
      values.forEach((_value: any, i: number) => {
        if (_value.type_id === id && _value.value === value) {
          index = i;
          return;
        }
      })
    } else {
      index = values.map((_value: any) => _value.type_id).indexOf(id)
    }

    if (index === -1) {
      values.push({
        type_id: id,
        value
      })
    } else {
      if (multiply) {
        values.splice(index, 1)
      } else {
        values[index].value = value
      }
    }

    setValues([...values])

  }

  const deleteProfile = () => {

    setLoading(true)

    apiProfileDelete(token, profile.id).then((response: any) => {

      dispatch(setProfilesAction({
        profiles: response.profiles
      }))
      dispatch(changeTypeAction({
        type: response.type
      }))
      navigate('/profiles')
      setLoading(false)

    }).catch(() => {
      setLoading(false)
    })

  }

  const deleteInfo = (
    <div className="modal-info">
      <span className="modal-info-title">Delete this profile</span>
      <span className="modal-info-subtitle">
        You are about to delete this profile permanently. Are you certain this is what you want?
      </span>
      <button className="modal-info-button red" onClick={() => {
        deleteProfile()
        setInfoModalObj(null)
      }}>Delete</button>
      <span className="modal-info-cancel-button" onClick={() => {
        setInfoModalObj(null)
      }}>Cancel</span>
    </div>
  )

  return (
    <div className="content-area">
      <div className="container with-flex-row">
        <div className="profiles-page-inner">
            <>
              <h1 className="profiles-page-title">{profile === undefined ? 'Create Profile' : 'Edit Profile'}</h1>
              <div className="edit-profile">
                {(loading || templates[type].edit.length === 0) && (
                  <div className="edit-profile-loading"/>
                )}
                <div className="edit-profile-inner">
                  <Modal
                    open={infoModalObj !== null}
                    onClose={() => {
                      setInfoModalObj(null)
                    }}
                    title={null}
                    content={(
                      infoModalObj
                    )}
                    hasBottom={false}
                  />
                  <div className="edit-profile-left">
                    <ImageUpload
                      type={type}
                      image={image}
                      onChange={(image) => {
                        setImage(image)
                      }}
                    />
                    <div className="edit-profile-username-area">
                      <span className="edit-profile-username-area-title">Username</span>
                      <input
                        type="text"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value)
                        }}
                      />
                    </div>
                    {(profile !== undefined) && (
                      <>
                        {profile.type === 'fun' ? (
                          <PublicFunPhotos
                            profile_id={profile.id}
                            photos={photos.filter(photo => photo.type === 'public')}
                            onChange={(photos) => {
                              setPhotos(photos)
                            }}
                            random={random}
                            onChangeRandom={(_random) => {
                              setRandom(_random)
                            }}
                          />
                        ) : (
                          <Photos
                            profile_id={profile.id}
                            photos={photos.filter(photo => photo.type === 'public')}
                            onChange={(photos) => {
                              setPhotos(photos)
                            }}
                            type={'public'}
                          />
                        )}
                        <Photos
                          profile_id={profile.id}
                          photos={photos.filter(photo => photo.type === 'private')}
                          onChange={(photos) => {
                            setPhotos(photos)
                          }}
                          type={'private'}
                        />
                      </>
                    )}
                    {profile !== undefined && (
                      <span className="edit-profile-delete-link" onClick={() => {
                        setInfoModalObj(deleteInfo)
                      }}>Delete profile</span>
                    )}
                  </div>
                  <div className="edit-profile-right">
                    {templates[type].edit.map((block: any) => {
                      return (
                        <>
                          <span className="edit-profile-block-title">{block.name}</span>
                          {block.elements.map((element: any) => {
                            return (
                              <Block
                                key={block.id}
                                block={element}
                                values={values}
                                onChange={changeValue}
                              />
                            )
                          })}
                        </>
                      )
                    })}
                  </div>
                </div>
                <div className="edit-profile-bottom">
                  <Link className="edit-profile-back" to={'/profiles'}>Cancel</Link>
                  <button className="edit-profile-submit" onClick={submit}>Submit</button>
                </div>
              </div>
            </>
        </div>
      </div>
    </div>
  );
}

export default ProfileEdit;
