import React, {useEffect, useState} from 'react';
import {
  apiProfileGetPrivatePhotos,
  apiProfileRequestPhotoAccess,
  apiProfileSharePhotoAccess
} from "../../../actions/profileActions";
import {useSelector} from "react-redux";
import {getCroppedImageUrl, getImageUrl} from "../../../helpers/helpers";
import Modal from "../../../components/ui/modal/Modal";
// @ts-ignore
import { Lightbox } from "react-modal-image";

const lock = require('../../../assets/lock.png');
const locked = require('../../../assets/locked.png');

type UserPagePhotosType = {
  profile_id: string
}

type UserPagePhotosState = {
  public_photos: [],
  src_photos: [],
  src_photos_count: number,
  src_access_status: number,
  target_photos_count: number,
  target_access_status: number
}

function UserPagePhotos({profile_id}: UserPagePhotosType) {

  const token = useSelector((state: any) => state.common.token);

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<UserPagePhotosState | null>(null)
  const [infoModalObj, setInfoModalObj] = useState<any>(null)
  const [selectedPhoto, setSelectedPhoto] = useState(null)

  const requestPhotoAccessObj = (
    <div className="modal-info">
      <span className="modal-info-title">Thank You</span>
      <span className="modal-info-subtitle">Request to view private photos has been sent</span>
      <button className="modal-info-button" onClick={() => {
        setInfoModalObj(null)
      }}>Got it</button>
    </div>
  )

  const mustRequestPhotoAccessObj = (
    <div className="modal-info">
      <span className="modal-info-title">Sorry</span>
      <span className="modal-info-subtitle">You need send request to view private photos</span>
      <button className="modal-info-button" onClick={() => {
        setInfoModalObj(null)
        requestPhotoAccess()
      }}>Send request</button>
      <div className="modal-info-cancel" onClick={() => {
        setInfoModalObj(null)
      }}>Cancel</div>
    </div>
  )

  const requestPhotoAccessDeclineObj = (
    <div className="modal-info">
      <span className="modal-info-title">Sorry!</span>
      <span className="modal-info-subtitle">Request to view private photos has been declined</span>
      <button className="modal-info-button" onClick={() => {
        setInfoModalObj(null)
      }}>Got it</button>
    </div>
  )

  const sharePhotoAccessObj = (
    <div className="modal-info">
      <span className="modal-info-title">Thank You</span>
      <span className="modal-info-subtitle">Your shared access was approved</span>
      <button className="modal-info-button" onClick={() => {
        setInfoModalObj(null)
      }}>Got it</button>
    </div>
  )

  useEffect(() => {

    loadData()

  }, [profile_id])

  const loadData = async () => {

    setLoading(true)

    const data = await apiProfileGetPrivatePhotos(token, profile_id)
    console.log(data)
    if (data.src_photos.length === 0) {
      data.src_photos = Array(data.src_photos_count).fill({
        path: undefined
      })
    }
    setData(data)

    setLoading(false)

  }

  if (loading || data === null) {
    return <div/>
  }

  const sharePhotoAccess = () => {

    apiProfileSharePhotoAccess(token, profile_id)
    setData({
      ...data,
      target_access_status: 3
    })
    setInfoModalObj(sharePhotoAccessObj)

  }

  const requestPhotoAccess = () => {

    apiProfileRequestPhotoAccess(token, profile_id)
    setData({
      ...data,
      src_access_status: 2
    })
    setInfoModalObj(requestPhotoAccessObj)

  }

  return (
    <>
      <Modal
        open={infoModalObj !== null}
        onClose={() => {
          setInfoModalObj(null)
        }}
        title={null}
        content={(
          infoModalObj
        )}
        hasBottom={false}
      />
      {selectedPhoto !== null && (
        <Lightbox
          large={getImageUrl(selectedPhoto)}
          onClose={() => {
            setSelectedPhoto(null)
          }}
        />
      )}
      <div className="user-page-private-photos">
        {data.public_photos.map((photo: any) => {
          return (
            <div className="user-page-private-photo">
              <img onClick={() => {
                setSelectedPhoto(photo.path)
              }} src={getCroppedImageUrl(photo.path, 70, 70)} alt="" className="user-page-private-photo-image"/>
            </div>
          )
        })}
        {data.src_photos.map((photo: any) => {
          return (
            <div className="user-page-private-photo">
              {photo.path ? (
                <img onClick={() => {
                  setSelectedPhoto(photo.path)
                }} src={getCroppedImageUrl(photo.path, 70, 70)} alt="" className="user-page-private-photo-image"/>
              ) : (
                <img onClick={() => {
                  setInfoModalObj(mustRequestPhotoAccessObj)
                }} src={locked} alt="" className="user-page-private-photo-icon"/>
              )}
            </div>
          )
        })}
      </div>
      {data && data.src_photos_count > 0  && data.src_access_status === 0 && (
        <div className="user-page-share" onClick={() => {
          setInfoModalObj(requestPhotoAccessDeclineObj)
        }}>
          <img src={lock} alt="" className="user-page-share-icon"/>
          <span className="user-page-share-text">Request to view private photos has been declined</span>
        </div>
      )}
      {data && data.src_photos_count > 0  && data.src_access_status === 1 && (
        <div className="user-page-share clickable" onClick={requestPhotoAccess}>
          <img src={lock} alt="" className="user-page-share-icon"/>
          <span className="user-page-share-text">Request to see Private Photos</span>
        </div>
      )}
      {data && data.src_photos_count > 0  && data.src_access_status === 2 && (
        <div className="user-page-share clickable" onClick={() => {
          setInfoModalObj(requestPhotoAccessObj)
        }}>
          <img src={lock} alt="" className="user-page-share-icon"/>
          <span className="user-page-share-text">Request to view private photos has been sent</span>
        </div>
      )}
      {data && data.target_photos_count > 0 && data.target_access_status === 1 && (
        <div className="user-page-share clickable" onClick={sharePhotoAccess}>
          <img src={lock} alt="" className="user-page-share-icon"/>
          <span className="user-page-share-text">Share my Private Photos</span>
        </div>
      )}
      {data && data.target_photos_count > 0 && data.target_access_status === 3 && (
        <div className="user-page-share clickable" onClick={() => {
          setInfoModalObj(sharePhotoAccessObj)
        }}>
          <img src={lock} alt="" className="user-page-share-icon"/>
          <span className="user-page-share-text">Your shared access was approved</span>
        </div>
      )}
    </>
  );
}

export default UserPagePhotos
