import React from 'react';
import './Footer.css';
import {
  APPLE_STORE_LINK,
  GOOGLE_PLAY_LINK,
  PRIVACY_POLICY_LINK,
  SITE_URL,
  TERMS_AND_CONDITIONS_LINK
} from "../../../core/links";

const appleIcon = require('../../../assets/apple.png');
const googleIcon = require('../../../assets/google.png');

function Footer() {
  return (
    <footer className="footer">
      <div className="container with-flex-row">
        <div className="footer-left">
          <a href={SITE_URL} className="footer-left-logo">
            <img src={'images/logo.svg'} alt=""/>
          </a>
          <span className="footer-left-copyright">© 2023 HEYHEYHEY. ALL RIGHTS RESERVED</span>
          <div className="footer-left-links">
            <a href={PRIVACY_POLICY_LINK} className="footer-left-link">Privacy Policy</a>
            <span className="footer-left-link-line">|</span>
            <a href={TERMS_AND_CONDITIONS_LINK} className="footer-left-link">Terms & Conditions</a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-apps">
            <div className="footer-apps">
              <a target={"_blank"} href={APPLE_STORE_LINK} className="footer-apps-link apple"><img src={appleIcon} alt=""/></a>
              <a target={"_blank"}  href={GOOGLE_PLAY_LINK} className="footer-apps-link google"><img src={googleIcon} alt=""/></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
