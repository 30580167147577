import React, {useEffect, useState} from 'react';
import './Notifications.css';
import {useDispatch, useSelector} from "react-redux";
import {apiNotificationsGet} from "../../actions/notificationActions";
import {getCroppedImageUrl} from "../../helpers/helpers";
import {Link} from "react-router-dom";
import {clearBadgesTypeAction} from "../../reducers/common";

const accessIcon = require('../../assets/notification/notification-access.png');
const approveIcon = require('../../assets/notification/notification-approve.png');
const declineIcon = require('../../assets/notification/notification-decline.png');
const favouriteIcon = require('../../assets/notification/notification-favourite.png');
const flexIcon = require('../../assets/notification/notification-flex.png');
const messagesIcon = require('../../assets/notification/notification-messages.png');
const viewIcon = require('../../assets/notification/notification-view.png');

function Notifications() {

  const dispatch = useDispatch()

  const token = useSelector((state: any) => state.common.token);
  const type = useSelector((state: any) => state.common.type);

  const [tab, setTab] = useState('all')
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    loadNotifications()
  }, [])

  const loadNotifications = async () => {

    setLoading(true)

    const notifications = await apiNotificationsGet(token, type);
    setNotifications(notifications)
    setLoading(false)

    dispatch(clearBadgesTypeAction({
      type
    }))

  }

  const getBadge = (type: string) => {

    let count = 0;

    if (type === 'all') {
      count = notifications.filter((notification: any) => notification.visible === 0).length
    } else {
      count = notifications.filter((notification: any) => notification.visible === 0 && notification.type === type).length
    }


    if (count > 0) {
      return <span className="notifications-nav-element-badge">{count}</span>
    }

  }

  return (
   <>
     <div className="content-area-top">
       <h1 className="content-area-title">Notifications</h1>
     </div>
     <div className="notifications">
       {loading ? (
          <div className="spinner"/>
       ) : (
          <div className="notifications-inner">
            <div className="notifications-nav">
              <div className={`notifications-nav-element ${tab === 'all' && 'active'}`} onClick={() => {
                setTab('all')
              }}>
                {getBadge('all')}
                <span className="notifications-nav-element-text">All</span>
              </div>
              <div className={`notifications-nav-element ${tab === 'view' && 'active'}`} onClick={() => {
                setTab('view')
              }}>
                {getBadge('view')}
                <img src={viewIcon} className="notifications-nav-element-icon" alt=""/>
              </div>
              <div className={`notifications-nav-element ${tab === 'favourite' && 'active'}`} onClick={() => {
                setTab('favourite')
              }}>
                {getBadge('favourite')}
                <img src={favouriteIcon} className="notifications-nav-element-icon" alt=""/>
              </div>
              <div className={`notifications-nav-element ${tab === 'flex' && 'active'}`} onClick={() => {
                setTab('flex')
              }}>
                {getBadge('flex')}
                <img src={flexIcon} className="notifications-nav-element-icon" alt=""/>
              </div>
              <div className={`notifications-nav-element ${tab === 'access' && 'active'}`} onClick={() => {
                setTab('access')
              }}>
                {getBadge('access')}
                <img src={accessIcon} className="notifications-nav-element-icon" alt=""/>
              </div>
              <div className={`notifications-nav-element ${tab === 'message' && 'active'}`} onClick={() => {
                setTab('message')
              }}>
                {getBadge('message')}
                <img src={messagesIcon} className="notifications-nav-element-icon" alt=""/>
              </div>
            </div>
            {notifications.map((notification: any) => {

              let show = true

              if (tab !== 'all') {
                if (notification.type !== tab) {
                  show = false
                }
              }

              if (show) {
                return (
                  <div className="notification">
                    <img src={getCroppedImageUrl(notification.src_profile.avatar, 45, 45)} className="notification-image" alt=""/>
                    <div className="notification-right">
                      <Link className="notification-name" to={`/user/${notification.src_profile.id}`}>{notification.src_profile.name}</Link>
                      {notification.type === 'view' && <span className="notification-text">looked at your profile</span>}
                      {notification.type === 'favourite' && <span className="notification-text">favorited you</span>}
                      {notification.type === 'flex' && <span className="notification-text">sent you a flex</span>}
                      {notification.type === 'link_accept' && <span className="notification-text">accepted your request to link profile</span>}
                      {notification.type === 'link_decline' && <span className="notification-text">declined your request to link profile</span>}
                      {notification.type === 'message' && <span className="notification-text">sent you a message</span>}
                    </div>
                  </div>
                )
              }

            })}
          </div>
       )}
     </div>
   </>
  );
}

export default Notifications;
