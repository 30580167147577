import React from 'react';
import {getCroppedImageUrl} from "../../../helpers/helpers";
import {Link} from "react-router-dom";
import {hopingForIcons, roleIcons, safetyPracticeIcons, twoIcon, visitedIcons} from "../../../core/icons";
import {useSelector} from "react-redux";

const locationIcon = require('../../../assets/location.png');

const onlineGreen = require('../../../assets/online/green.png');
const onlineLurking = require('../../../assets/online/lurking.png');
const onlineRed = require('../../../assets/online/red.png');

const muslIcon = require('../../../assets/musl_icon.png');

function User({user}: any) {

  const visibles = useSelector((state: any) => state.common.visibles);

  const countDistance = () => {

    let feet = user.distance * 5280;

    if (feet < 250) {
      return '<250 feet';
    }

    if (feet < 5280) {
      return parseInt(feet.toString()) + ' feet';
    }

    let miles = feet / 5280;

    if (miles < 20) {
      return miles.toFixed(1) + ' mi';
    }

    return parseInt(miles.toString()) + ' mi';

  }

  const getOnlineIcon = () => {

    if (user.active === 0) {
      return <img src={onlineRed} className="user-location-circle"/>
    }

    if (user.online === 1) {
      return <img src={onlineGreen} className="user-location-circle"/>
    }

    return <img src={onlineLurking} className="user-location-circle"/>

  }

  const checkSafetyPracticeIcon = () => {

    if (user.type === 'fun') {
      if (
        user.safety_practice !== null &&
        user.safety_practice !== undefined &&
        user.safety_practice !== ""
      ) {
        let safety_practice = user.safety_practice.split(',');
        if (safety_practice.length === 1) {
          return <img src={safetyPracticeIcons[safety_practice[0]]} alt="" className="user-dop-icon"/>
        }
        if (safety_practice.length > 1) {
          return (
            <img src={twoIcon} alt="" className="user-dop-icon"/>
          );
        }
      }
    }

  }

  const checkHopingForIcon = () => {

    if (user.type === 'flirt') {
      if (
        user.hoping_for !== null &&
        user.hoping_for !== undefined &&
        user.hoping_for !== ""
      ) {
        let hoping_for = user.hoping_for.split(',');
        if (hoping_for.length === 1) {
          return <img src={hopingForIcons[hoping_for[0]]} alt="" className="user-dop-icon"/>
        }
        if (hoping_for.length > 1) {
          return (
            <img src={twoIcon} alt="" className="user-dop-icon"/>
          );
        }
      }
    }

  }

  const checkRoleIcon = () => {

    if (user.type === 'fun') {
      if (
        user.role !== null &&
        user.role !== undefined &&
        user.role !== ""
      ) {
        let roles = user.role.split(',');
        if (roles.length === 1) {
          return <img src={roleIcons[roles[0]]} alt="" className="user-dop-icon"/>
        }
        if (roles.length > 1) {
          return (
            <img src={twoIcon} alt="" className="user-dop-icon"/>
          );
        }
      }
    }

  }

  return (
    <Link to={`/user/${user.id}`} className="user" id={user.id}>
      <div className="user-inner">
        {user.user.hey === 0 && (
          <img src={muslIcon} alt="" className="user-hey"/>
        )}
        {visibles.indexOf(user.id) !== -1 && <img src={visitedIcons[user.type]} alt="" className="user-visited"/>}
        <img src={`${getCroppedImageUrl(user.avatar, 100, 100)}`} alt="" className="user-image"/>
        <div className="user-bottom">
          {getOnlineIcon()}
          <span className="user-name">{user.name}</span>
          <div className="user-dop">
            <span className="user-location">
              <img src={locationIcon} className="user-location-icon" alt=""/>
              <span className="user-location-text">{countDistance()}</span>
            </span>
            <div className="user-dop-icons">
              {checkHopingForIcon()}
              {checkRoleIcon()}
              {checkSafetyPracticeIcon()}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default User;
