import thunk from 'redux-thunk';
import {applyMiddleware, combineReducers, createStore} from "redux";

import {commonReducer} from "./common";
import {profileReducer} from "./profile";
import {searchReducer} from "./search";

type AppState = {
  common: any
}

declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
}

const rootReducer = combineReducers({
  common: commonReducer,
  profile: profileReducer,
  search: searchReducer
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
