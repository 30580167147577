import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import {useSelector} from "react-redux";
import Menu from "./components/menu/Menu";
import {DEFAULT_BACKGROUND, FLIRT_BACKGROUND, FRIEND_BACKGROUND, FUN_BACKGROUND} from "../core/colors";
import Advertising from "./components/advertising/Advertising";

function Auth() {

  const navigate = useNavigate();

  const isLoggedIn = useSelector((state: any) => state.common.isLoggedIn);
  const type = useSelector((state: any) => state.common.type);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    }
  }, [isLoggedIn])

  const getBackgroundColor = () => {

    if (type === 'flirt') {
      return FLIRT_BACKGROUND
    }

    if (type === 'fun') {
      return FUN_BACKGROUND
    }

    if (type === 'friend') {
      return FRIEND_BACKGROUND
    }

    return DEFAULT_BACKGROUND

  }

  return (
    <>
      <Header onMobileMenuOpen={() => {
        setMobileMenuOpen(!mobileMenuOpen)
      }}/>
      <div className="content-area" style={{
        backgroundColor: getBackgroundColor()
      }}>
        <div className="content-area-container">
          {mobileMenuOpen && (
            <>
              <div className="mobile-menu-fade" onClick={() => {
                setMobileMenuOpen(false)
              }}/>
              <Menu className={'mobile-menu'} onClick={() => {
                setMobileMenuOpen(false)
              }}/>
            </>
          )}
          <Menu className={'aside'}/>
          <div className="content-area-inner">
            <Outlet/>
          </div>
          <Advertising/>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Auth;
