import React, {useEffect, useState} from 'react';
import './Support.css';
import {useSelector} from "react-redux";
import Radio from "../../components/ui/Radio";
import {apiUserSendSupport} from "../../actions/userActions";

const supportIcon = require('../../assets/support.png');

function Support() {

  const token = useSelector((state: any) => state.common.token);

  const [problem, setProblem] = useState('')

  const [appCrash, setAppCrash] = useState(false)
  const [compliant, setCompliant] = useState(false)
  const [restoreTheAccess, setRestoreTheAccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const submit = () => {

    setLoading(true)

    apiUserSendSupport(
      token,
      +appCrash,
      +compliant,
      +restoreTheAccess,
      problem
    )



  }

  return (
   <>
     <div className="content-area-top">
       <h1 className="content-area-title">Support</h1>
     </div>
    <div className="support">
      {loading ? (
        <div className="spinner"/>
      ) : (
        <>
          <div className="support-left">
            <span className="support-label">What do you need help with?</span>
            <div className="support-radios">
              <Radio
                label={'App Crash'}
                checked={appCrash}
                onChange={(checked) => {
                  setAppCrash(checked)
                  setCompliant(false)
                  setRestoreTheAccess(false)
                }}
              />
              <Radio
                label={'Compliant'}
                checked={compliant}
                onChange={(checked) => {
                  setCompliant(checked)
                  setAppCrash(false)
                  setRestoreTheAccess(false)
                }}
              />
              <Radio
                label={'Restore the access'}
                checked={restoreTheAccess}
                onChange={(checked) => {
                  setRestoreTheAccess(checked)
                  setAppCrash(false)
                  setCompliant(false)
                }}
              />
            </div>
            <span className="support-label">Your problem:</span>
            <div className="support-large-text">
              <textarea
                className="support-large-text-input"
                placeholder={"Tell Musl your problem"}
                value={problem}
                onChange={(event) => {
                  setProblem(event.target.value)
                }}
              />
            </div>
            <button className="support-submit" onClick={submit}>Submit</button>
          </div>
          <div className="support-right">
            <img src={supportIcon} className="support-icon" alt=""/>
          </div>
        </>
      )}
    </div>
   </>
  );
}

export default Support;
