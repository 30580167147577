import {S3_URL} from "../core/links";
import explore from "../pages/visibility/components/Explore";

export function validatePassword(password: string) {
  if (/^(?=.*[A-Z])(?=.*\d).+$/.test(password)) {
    return true;
  }

  return false;
}

export function validateEmail(email: string) {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function getCroppedImageUrl(image: string, width: number, height: number) {
  return `${S3_URL}/` + width + 'x' + height + image;
}

export function getImageUrl(image: string) {
  return `${S3_URL}${image}`;
}

export function capitalizeFirstLetter(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export const checkElementInArray = (array: any[], element: any) => {

  if (array.indexOf(element) !== -1) {
    array.splice(array.indexOf(element), 1)
    return [...array]
  }

  return [...array, element]

}

export const isEmpty = (value: string) => {
  return value === "" || value === undefined || value === null
}
