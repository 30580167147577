import React, {useState} from 'react';
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps"
import {useDispatch, useSelector} from "react-redux";
import {setExploreAction, setExploreLocationAction} from "../../../reducers/common";
import {apiUserChangeExplore, apiUserChangeExploreLocation} from "../../../actions/userActions";
import Switch from "react-switch";
import {MapComponent} from "../../../components/ui/MapComponent";

function Explore() {

  const dispatch = useDispatch()

  const token = useSelector((state: any) => state.common.token);
  const explore = useSelector((state: any) => state.common.user.explore);
  const explore_lat = useSelector((state: any) => state.common.user.explore_lat);
  const explore_lng = useSelector((state: any) => state.common.user.explore_lng);
  const lat = useSelector((state: any) => state.common.user.lat);
  const lng = useSelector((state: any) => state.common.user.lng);

  let mapLat = explore_lat !== null ? parseFloat(explore_lat) : lat
  if (mapLat === null) {
    mapLat = 34.09001
  }
  let mapLng = explore_lng !== null ? parseFloat(explore_lng) : lng
  if (mapLng === null) {
    mapLng = -118.36174
  }

  const changeExploreLocation = (lat: number, lng: number) => {

    apiUserChangeExploreLocation(token, lat, lng)
    dispatch(setExploreLocationAction({
      explore_lat: lat,
      explore_lng: lng
    }))

  }

  const changeExplore = (checked: boolean) => {

    apiUserChangeExplore(token, +checked)
    dispatch(setExploreAction({
      explore: +checked
    }))

  }

  return (
    <div>
      <div className="visibility-tab-content-element">
        <span className="visibility-tab-content-element-label">On/Off</span>
        <div className="visibility-tab-content-element-right">
          <Switch
            onChange={changeExplore}
            checked={explore}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      </div>
      <MapComponent
        containerElement={<div style={{ height: `300px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        // @ts-ignore
        onClick={(event) => {
          const lat = event.latLng?.lat();
          const lng = event.latLng?.lng();
          changeExploreLocation(lat, lng);
        }}
        coords={{ lat: mapLat, lng: mapLng}}
      />
      <span className="visibility-map-bottom-text">Click the map to explore new location</span>
    </div>
  );
}

export default Explore;
