import React, {useState} from 'react';
import {useSelector} from "react-redux";

function GeneralInfoTab({}: any) {

  const email = useSelector((state: any) => state.common.user.email);

  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="settings-tab">
        <div className={`settings-tab-top ${open  ? 'active' : ''}`} onClick={() => {
          setOpen(!open)
        }}>
          <span className="settings-tab-top-label">Account Details</span>
          <span className="settings-tab-top-icon">{open ? '-' : '+'}</span>
        </div>
        {open && (
          <div className="settings-tab-content">
            <div className="general-info-element">
             <div className="general-info-element-top">
               <span className="general-info-element-label">Email</span>
               <input type="text" value={email} disabled={true} className="general-info-element-input"/>
             </div>
              <span className="general-info-element-change">Change email</span>
            </div>
            <div className="general-info-element">
              <div className="general-info-element-top">
                <span className="general-info-element-label">Password</span>
                <input type="text" value="*********" disabled={true} className="general-info-element-input"/>
              </div>
              <span className="general-info-element-change">Change password</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GeneralInfoTab
