import React, {useEffect, useState} from 'react';
import './Users.css';
import User from "./component/User";
import {useSelector} from "react-redux";
import {apiProfilesSearch} from "../../actions/profileActions";
import {Link, useNavigate} from "react-router-dom";

const backIcon = require('../../assets/back.png');

function Users() {

  const navigate = useNavigate();

  const token = useSelector((state: any) => state.common.token);
  const type = useSelector((state: any) => state.common.type);

  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("mode");

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])

  const searchValues = useSelector((state: any) => state.search.values)
  const searchUsername = useSelector((state: any) => state.search.username)

  useEffect(() => {
    loadProfiles()
  }, [])

  useEffect(() => {
    loadProfiles()
  }, [type])

  const loadProfiles = async () => {

    setLoading(true)
    const users = await apiProfilesSearch(
      token,
      type,
      64,
      0,
      mode === 'search' ? JSON.stringify([]) : '',
      mode === 'search' ? searchUsername : '',
      mode === 'search' ? 'search' : 'list',
      mode === 'search' ? convertSearchValues(searchValues) : ''
    )

    setUsers(users)
    setLoading(false)

  }

  const convertSearchValues = (values: []) => {

    const array: string[] = [];
    values.forEach((value: any) => array.push(JSON.stringify(value)))

    return JSON.stringify(array);

  }

  return (
   <>
     <div className="content-area-top">
       <div className="content-area-top">
         {mode === 'search' && (
           <div className="content-area-top-left" onClick={() => {
             navigate('/search')
           }}>
             <img src={backIcon} alt="" className="content-area-top-left-icon"/>
           </div>
         )}
         <h1 className="content-area-title"/>
       </div>
     </div>
     <div className="users">
       {loading ? (
         <div className="users-loader">
           <div className="spinner"/>
         </div>
       ) : (
         <>
           {mode === 'search' && users.length === 0 ? (
           <div className="users-empty">
             <span className="users-empty-text">There is no one that meets your search criteria.</span>
             <span className="users-empty-text">So you need to be just a little less picky</span>
             <Link className="users-empty-button" to={'/search'}>Search</Link>
           </div>
           ) : (
           <>
             {users.map((user: any) => {
               return (
                 <User key={user.id} user={user}/>
               )
             })}
           </>
           )}
         </>
       )}
     </div>
   </>
  );
}

export default Users;
