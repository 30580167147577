import React, {useEffect} from 'react';
import './Messages.css';
import ChatItem from "./components/ChatItem";
import {useDispatch, useSelector} from "react-redux";
import ChatWindow from "./components/ChatWindow";
import {setActiveChatAction} from "../../reducers/profile";
import {clearMessagesBadgesTypeAction} from "../../reducers/common";
import {apiNotificationsUpdateMessagesVisible} from "../../actions/notificationActions";
import {useNavigate} from "react-router-dom";

const backIcon = require('../../assets/back.png');

function Messages() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const type = useSelector((state: any) => state.common.type);
  const token = useSelector((state: any) => state.common.token);
  const profiles = useSelector((state: any) => state.common.profiles);
  const chats = useSelector((state: any) => state.profile.chats);
  const chat = useSelector((state: any) => state.profile.active_chat);
  const isChatsPending = useSelector((state: any) => state.profile.isChatsPending);

  const myProfile = profiles.find((profile: any) => {return profile.type === type})
  if (myProfile === undefined) {
    navigate('/profiles')
  }

  useEffect(() => {

    dispatch(clearMessagesBadgesTypeAction({
      type
    }))
    apiNotificationsUpdateMessagesVisible(token, myProfile.id)

  }, [])


  if (isChatsPending) {
    return <div/>
  }

  return (
    <div className="profiles-page">
      <div className="container with-flex-row">
        <div className="profiles-page-inner">
          <>
            <div className="content-area-top">
              <div className="content-area-top-left" onClick={() => {
                navigate(-1)
              }}>
                <img src={backIcon} alt="" className="content-area-top-left-icon"/>
              </div>
              <h1 className="content-area-title">Messages</h1>
            </div>
            <div className="messages">
              <div className="messages-left">
                {chats.sort((a: any, b: any) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0)).map((_chat: any) => {
                  return (
                    <ChatItem onClick={() => {
                      dispatch(setActiveChatAction({
                        active_chat: _chat
                      }))
                    }} active={chat.id === _chat.id} key={_chat.id} chat={_chat}/>
                  )
                })}
              </div>
              <div className="messages-right">
                {chat !== null && <ChatWindow chat={chat}/>}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Messages;
