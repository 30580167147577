import axios from "axios";
import {API_URL} from "../core/links";

export const apiProfilesSearch = (
  token: string,
  type: string,
  limit: number,
  offset: number,
  tags = '',
  username = '',
  mode = 'list',
  values = ''
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/profiles.get_redesign', {
      type,
      values: values,
      tags: tags,
      mode: mode,
      limit,
      offset: offset,
      username: username,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profiles);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilesGetBlocked = (
  token: string,
  type: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/profiles.get_blocked', {
      type
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profiles);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileLoad = (
  token: string,
  id: string
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/profile.get?profile_id=${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.profile);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetElements = (
  token: string,
  type: string
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/profile.get_elements_redesign?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.elements);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetData = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_data?profile_id=${profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGet = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get?profile_id=${profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileUploadAvatar = (token: string, file: any): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append("file", file);

    axios.post(API_URL + '/profile.upload_avatar', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilePhotoCreate = (token: string, profile_id: string, file: any, type: 'private' | 'public'): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append("file", file);
    data.append("profile_id", profile_id)
    data.append('type', type)

    axios.post(API_URL + '/v2/photo.create', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilePublicPhotoCreate = (token: string, profile_id: string, file: any): any => {

  return new Promise((resolve, reject) => {

    let data = new FormData();
    data.append("file", file);
    data.append("profile_id", profile_id)

    axios.post(API_URL + '/v3/photo.create_public', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfilePhotoDelete = (token: string, profile_id: string, photo_id: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/photo.delete', {
      id: photo_id,
      profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileCreate = (
  token: string,
  type: string,
  name: string,
  image: string,
  values: {
    type_id: string,
    value: string
  }[]
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v2/profile.create`, {
      name,
      type,
      image,
      values: JSON.stringify(values.map(value => JSON.stringify(value)))
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileEdit = (
  token: string,
  profile_id: string,
  name: string,
  image: string,
  random: 0 | 1,
  values: {
    type_id: string,
    value: string
  }[]
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v2/profile.edit`, {
      name,
      profile_id,
      image,
      random,
      values: JSON.stringify(values.map(value => JSON.stringify(value)))
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileFlex = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.flex`, {
      'target_profile_id': profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileFavourite = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.favourite`, {
      'target_profile_id': profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileBlock = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.block`, {
      'target_profile_id': profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetStatistics = (
  token: string,
  type: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_statistics?type=${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}


export const apiProfileGetPrivatePhotos = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_private_photos?profile_id=${profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileRequestPhotoAccess = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.request_photo_access`, {
      target_profile_id: profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileSharePhotoAccess = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.share_photo_access`, {
      target_profile_id: profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileDelete = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/profile.delete`, {
      profile_id: profile_id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileGetNote = (
  token: string,
  target_profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/profile.get_note?target_profile_id=${target_profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.note);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiProfileChangeNote = (
  token: string,
  target_profile_id: string,
  text: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/v2/profile.change_note`, {
      target_profile_id,
      text
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}


