import React from 'react';

type FieldProps = {
  value: string,
  label?: string,
  type?: string,
  id: string,
  className: string,
  error?: string
  onChange: (value: string) => void,

}

function Field({value, label, type = 'text', id, className, error, onChange}: FieldProps) {
  return (
    <div className={`${className}-area`}>
      {label && <label htmlFor={id} className={`${className}-label`}>{label}</label>}
      <input
        type={type}
        className={`${className}-input ${error && 'error'}`}
        id={id}
        value={value}
        onChange={(event: any) => {
          onChange(event.target.value)
        }}
      />
      {error &&  <span className="login-page-fields-error">{error}</span>}
    </div>
  );
}

export default Field;
