import Cookies from "js-cookie";
import {checkElementInArray} from "../helpers/helpers";

const USER_SIGN_IN = 'USER_SIGN_IN';
const USER_SIGN_OUT = 'USER_SIGN_OUT';
const CHANGE_TYPE = 'CHANGE_TYPE';
const SET_TEMPLATES = 'SET_TEMPLATES';
const SET_USER_DATA = 'SET_USER_DATA';
const VISIBLE = 'VISIBLE';
const SET_BADGES = 'SET_BADGES';
const CLEAR_BADGES_TYPE = 'CLEAR_BADGES_TYPE';
const CLEAR_MESSAGES_BADGES_TYPE = 'CLEAR_MESSAGES_BADGES_TYPE';
const SET_PROFILES = 'SET_PROFILES';
const SET_LOCATION = 'SET_LOCATION';
const SET_PHOTOS_IN_SWITCHER = 'SET_PHOTOS_IN_SWITCHER'
const SET_EXPLORE = 'SET_EXPLORE';
const SET_SEX = 'SET_SEX';
const SET_EXPLORE_LOCATION = 'SET_EXPLORE_LOCATION';
const SET_SAFE_FOR_WORK = 'SET_SAFE_FOR_WORK';
const SET_STEALTH_MODE = 'SET_STEALTH_MODE';


const defaultState = {
  isLoggedIn: false,
  token: null,
  type: null,
  user: {
    id: null,
    email: null,
    lat: null,
    lng: null,
    explore_lat: null,
    explore_lng: null,
    explore: 0,
    photos_in_switcher: 0,
    stealth_mode: 0,
    safe_for_work: {
      enabled: 0,
      lat: null,
      lng: null,
      begin: null,
      end: null
    },
    sex: null,
  },
  profiles: [],
  templates: {
    friend: {
      single: [],
      edit: [],
      search: [],
    },
    flirt: {
      single: [],
      edit: [],
      search: [],
    },
    fun: {
      single: [],
      edit: [],
      search: [],
    }
  },
  allows: [],
  visibles: [],
  badges: {
    friend: {
      notifications: 0,
      messages: 0
    },
    flirt: {
      notifications: 0,
      messages: 0
    },
    fun: {
      notifications: 0,
      messages: 0
    }
  }
};

type ActionProps = {
  type: string,
  payload: any
}

export const commonReducer = (state = defaultState, action: ActionProps) => {
  switch (action.type) {
    case USER_SIGN_IN:
      Cookies.set('token', action.payload.token, { path: '' })
      const activeProfile = action.payload.profiles.find((profile: any) => profile.active === 1)
      return {
        ...state,
        isLoggedIn: true,
        user: {
          id: action.payload.user.id,
          email: action.payload.user.email,
          lat: action.payload.user.lat,
          lng: action.payload.user.lng,
          photos_in_switcher: action.payload.user.photos_in_switcher,
          explore: action.payload.user.explore,
          explore_lat: action.payload.user.explore_lat,
          explore_lng: action.payload.user.explore_lng,
          stealth_mode: action.payload.user.stealth_mode,
          safe_for_work: {
            enabled: action.payload.user.safe_for_work,
            lat: action.payload.user.safe_for_work_lat,
            lng: action.payload.user.safe_for_work_lng,
            begin: action.payload.user.safe_for_work_begin,
            end: action.payload.user.safe_for_work_end,
          },
          sex: action.payload.user.sex
        },
        profiles: action.payload.profiles,
        token: action.payload.token,
        type: activeProfile !== undefined ? activeProfile.type : null
      };
    case USER_SIGN_OUT:
      Cookies.remove('token', { path: '' })
      return {
        ...defaultState
      };
    case CHANGE_TYPE:
      return {
        ...state,
        type: action.payload.type
      };
    case SET_PROFILES:
      return {
        ...state,
        profiles: action.payload.profiles
      };
    case SET_BADGES:
      return {
        ...state,
        badges: action.payload.badges
      };
    case VISIBLE:
      return {
        ...state,
        visibles: checkElementInArray(state.visibles, action.payload.profile_id)
      };
    case CLEAR_BADGES_TYPE:
      return {
        ...state,
        badges: {
          ...state.badges,
          [action.payload.type]: {
            notifications: 0,
            messages: 0
          }

        }
      };
    case CLEAR_MESSAGES_BADGES_TYPE:
      return {
        ...state,
        badges: {
          ...state.badges,
          [action.payload.type]: {
            messages: 0,
            // @ts-ignore
            notifications: state.badges[action.payload.type].notifications - state.badges[action.payload.type].messages
          }

        }
      };
    case SET_TEMPLATES:
      return {
        ...state,
        templates: action.payload.templates,
      };
    case SET_PHOTOS_IN_SWITCHER:
      return {
        ...state,
        user: {
          ...state.user,
          photos_in_switcher: action.payload.photos_in_switcher
        }
      };
    case SET_USER_DATA:
      return {
        ...state,
        allows: action.payload.allows,
        visibles: action.payload.visibles
      };
    case SET_LOCATION:
      return {
        ...state,
        user: {
          ...state.user,
          lat: action.payload.lat,
          lng: action.payload.lng
        },
      };
    case SET_SEX:
      return {
        ...state,
        user: {
          ...state.user,
          sex: action.payload.sex
        },
      };
    case SET_EXPLORE_LOCATION:
      return {
        ...state,
        user: {
          ...state.user,
          explore_lat: action.payload.explore_lat,
          explore_lng: action.payload.explore_lng
        },
      };
    case SET_EXPLORE:
      return {
        ...state,
        user: {
          ...state.user,
          explore: action.payload.explore,
        },
      };
    case SET_SAFE_FOR_WORK:
      return {
        ...state,
        user: {
          ...state.user,
          safe_for_work: {
            ...action.payload.safe_for_work,
          }
        },
      };
    case SET_STEALTH_MODE:
      return {
        ...state,
        user: {
          ...state.user,
         stealth_mode: action.payload.stealth_mode
        },
      };
    default:
      return state;
  }
};

export const userSignInAction = (payload : object) => ({type: USER_SIGN_IN, payload});
export const userSignOutAction = () => ({type: USER_SIGN_OUT});
export const changeTypeAction = (payload : object) => ({type: CHANGE_TYPE, payload});
export const setTemplatesAction = (payload : object) => ({type: SET_TEMPLATES, payload});
export const visibleAction = (payload : object) => ({type: VISIBLE, payload});
export const setUserDataAction = (payload : object) => ({type: SET_USER_DATA, payload});
export const setProfilesAction = (payload : object) => ({type: SET_PROFILES, payload});
export const setLocationAction = (payload : object) => ({type: SET_LOCATION, payload});
export const setSexAction = (payload : object) => ({type: SET_SEX, payload});
export const setPhotosInSwitcherAction = (payload : object) => ({type: SET_PHOTOS_IN_SWITCHER, payload});
export const setBadgesAction = (payload : object) => ({type: SET_BADGES, payload});
export const setExploreAction = (payload : object) => ({type: SET_EXPLORE, payload});
export const setExploreLocationAction = (payload : object) => ({type: SET_EXPLORE_LOCATION, payload});
export const setSafeForWorkAction = (payload : object) => ({type: SET_SAFE_FOR_WORK, payload});
export const setStealthModeAction = (payload : object) => ({type: SET_STEALTH_MODE, payload});
export const clearBadgesTypeAction = (payload : object) => ({type: CLEAR_BADGES_TYPE, payload});
export const clearMessagesBadgesTypeAction = (payload : object) => ({type: CLEAR_MESSAGES_BADGES_TYPE, payload});
