import React from 'react';

type RadioType = {
  label: string,
  checked: boolean,
  onChange: (checked: boolean) => void
}

function Radio({label, checked, onChange}: RadioType) {
  return (
    <label className="edit-profile-checkbox">
      <input type="checkbox" checked={checked} onChange={(event) => {
        onChange(event.target.checked)
      }}/>
      <div className="edit-profile-checkbox-checkmark rounded">
        <div className="edit-profile-checkbox-checkmark-inner rounded"/>
      </div>
      <span className="edit-profile-checkbox-label">{label}</span>
    </label>
  );
}

export default Radio
