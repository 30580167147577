import React, {useEffect, useState} from 'react';
import './Users.css';
import User from "./component/User";
import {useSelector} from "react-redux";
import {apiProfilesGetBlocked} from "../../actions/profileActions";
import {useNavigate} from "react-router-dom";

const backIcon = require('../../assets/back.png');

function BlockedUsers() {

  const navigate = useNavigate()

  const token = useSelector((state: any) => state.common.token);
  const type = useSelector((state: any) => state.common.type);

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])

  useEffect(() => {
    loadProfiles()
  }, [])

  useEffect(() => {
    loadProfiles()
  }, [type])

  const loadProfiles = async () => {

    setLoading(true)
    const users = await apiProfilesGetBlocked(
      token,
      type
    )

    setUsers(users)
    setLoading(false)

  }

  return (
   <>
     <div className="content-area-top">
       <div className="content-area-top-left" onClick={() => {
         navigate('/settings')
       }}>
         <img src={backIcon} alt="" className="content-area-top-left-icon"/>
       </div>
       <h1 className="content-area-title">Blocked users</h1>
     </div>
     <div className="users">
       {loading ? (
         <div className="users-loader">
           <div className="spinner"/>
         </div>
       ) : (
         <>
           {users.map((user: any) => {
             return (
               <User key={user.id} user={user}/>
             )
           })}
         </>
       )}
     </div>
   </>
  );
}

export default BlockedUsers;
