import React, {useRef, useState} from 'react';
import {
  apiProfilePhotoDelete,
  apiProfilePublicPhotoCreate
} from "../../../actions/profileActions";
import {useSelector} from "react-redux";
import {getCroppedImageUrl} from "../../../helpers/helpers";
import {PhotoType} from "./Photos";
import Switch from "react-switch";

const placeholderImage = require('../../../assets/placeholder.png');
const iconCircleCloseImage = require('../../../assets/icon-circle-close.png');

type PublicFunPhotosProps = {
  photos: PhotoType[]
  profile_id: string,
  random: 0 | 1,
  onChange: (photos: PhotoType[]) => void
  onChangeRandom: (random: 0 | 1) => void
}

function PublicFunPhotos({photos, onChange, profile_id, random, onChangeRandom}: PublicFunPhotosProps) {

  const [loading, setLoading] = useState(false)

  const token = useSelector((state: any) => state.common.token)

  const inputFile = useRef<any>(null)

  const uploadPhoto = () => {
    inputFile.current.click();
  }

  const onFileChange = async (event: any) => {

    if (event.target.files.length > 0) {

      setLoading(true)

      const response = await apiProfilePublicPhotoCreate(
        token,
        profile_id,
        event.target.files[0],
      )
      onChange(response.photos)

      setLoading(false)

    }

  }

  const deletePhoto = async (photo_id: string) => {
    setLoading(true)
    const response = await apiProfilePhotoDelete(token, profile_id, photo_id)
    onChange(response.photos)
    setLoading(false)
  }

  return (
    <div className="edit-profile-private-photo">
      <span className="edit-profile-private-photo-title">Public Photos</span>
      <span className="edit-profile-private-photo-sub-title">In FUN, your public photos may be adult</span>
      {loading ? (
        <div className="edit-profile-private-photo-loader">
          <div className="spinner"/>
        </div>
      ) : (
        <div className="edit-profile-private-photo-elements">
          <input
            accept={"image/jpeg, image/png, image/gif"}
            type="file"
            ref={inputFile}
            style={{display: "none"}}
            onChange={onFileChange}
          />
          <Photo photo={photos.length > 0 ? photos[0] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
          <Photo photo={photos.length > 1 ? photos[1] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
          <Photo photo={photos.length > 2 ? photos[2] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
          <Photo photo={photos.length > 3 ? photos[3] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
          <Photo photo={photos.length > 4 ? photos[4] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
          <Photo photo={photos.length > 5 ? photos[5] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
        </div>
      )}
      <div className="edit-profile-private-switcher">
        <span className="edit-profile-private-switcher-label">Randomly display my main profile photo</span>
        <div className="edit-profile-private-switcher-right">
          <Switch
            onChange={(checked) => {
              onChangeRandom(checked ? 1 : 0)
            }}
            checked={random === 1}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      </div>
    </div>
  );
}

function Photo({photo, onUpload, onDelete}: {
  photo: null | PhotoType,
  onUpload: () => void
  onDelete: (photo_id: string) => void
}) {

  if (photo !== null) {
    return (
      <div className="edit-profile-private-photo-element">
        <div className="edit-profile-private-photo-element-close" onClick={() => {
          onDelete(photo.id)
        }}>
          <img className="edit-profile-private-photo-element-close-icon" src={iconCircleCloseImage} alt=""/>
        </div>
        <img
          className="edit-profile-private-photo-element-image"
          src={getCroppedImageUrl(photo.path, 150, 150)}
          alt=""
        />
        {photo.adult === 1 && (
          <span className="edit-profile-private-photo-adult">Adult</span>
        )}
        {photo.adult === 1 && photo.status === 1 && (
          <span className="edit-profile-private-photo-label">On review</span>
        )}
      </div>
    )
  }

  return (
    <div className="edit-profile-private-photo-element">
      <img
        onClick={onUpload}
        className="edit-profile-private-photo-element-image"
        src={placeholderImage}
        alt=""
      />
    </div>
  )

}

export default PublicFunPhotos
