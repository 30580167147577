import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/normalize.css'
import './css/index.css';
import App from './App';
import {store} from "./reducers/store";
import {Provider} from "react-redux";

console.log = () => {}
console.error = () => {}
console.debug = () => {}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <App/>
  </Provider>
);
